<template>
  <div class="pwm-page flex-col">
    <div class="pwm-block1 flex-col">
      <div class="pwm-bd1 flex-col">
        <div class="pwm-mod1 flex-col">
          <headInfo :type="type"></headInfo>
          <span class="pwm-txt3">专业级仓储WMS</span>
          <span class="pwm-word3"
            >业务更匹配、出库更高效、任务更轻松、数据更直观、仓库更智能</span
          >
          <!-- <div class="pwm-group2 flex-col" @click="toNewPage(5)">
            <span class="pwm-txt4">立即试用</span>
          </div> -->
        </div>
        <div class="pwm-mod2 flex-col">
          <div class="pwm-outer1 flex-col"></div>
        </div>
      </div>
      <span class="pwm-txt5">产品介绍</span>
      <span class="pwm-word4"
        >全渠道管理，多层级多仓多货主多模式，根据不同业务提供差异化的行业解决方案</span
      >
      <div class="pwm-bd3 flex-row">
        <img
          class="pwm-img22"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/wmsone.png"
        />
        <img
          class="pwm-img23"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/wmstwo.png"
        />
        <div class="pwm-main19 flex-col"></div>
        <div class="pwm-main20 flex-col"></div>
      </div>
      <div class="pwm-bd4 flex-row justify-between">
        <span class="pwm-txt6">直观数据</span>
        <span class="pwm-word5">智能算法</span>
        <span class="pwm-txt7">信息安全</span>
        <span class="pwm-word6">判断精准</span>
      </div>
      <div class="pwm-bd5 flex-row justify-between">
        <span class="pwm-word7"
          >库位、库位热度、拣选路径可视化，操作员绩效可视化</span
        >
        <span class="pwm-txt8">仓库最优拣选路径，波次、拣货、盘点策略等</span>
        <span class="pwm-info3"
          >对企业信息进行加密，绝对安全保障，可私有化部署</span
        >
        <span class="pwm-word8"
          >补货预警、过期预警等，让仓库管理员无忧作业</span
        >
      </div>
      <div class="pwm-bd6 flex-col">
        <div class="pwm-main1 flex-col">
          <span class="pwm-info4">产品亮点</span>
          <span class="pwm-info5"
            >信息化、智能化、无纸化高效追溯管理，实现信息透明化、数据实时准确</span
          >
          <div class="pwm-main2 flex-col"></div>
          <div class="pwm-main3 flex-row justify-between">
            <div class="pwm-main4 flex-col">
              <div class="pwm-block2 flex-col">
                <div class="pwm-group3 flex-col">
                  <span class="pwm-txt9">全报表覆盖</span>
                  <span class="pwm-paragraph1"
                    >多种报表明细<br />减轻工作负担<br />数据一目了然</span
                  >
                  <img
                    class="pwm-img3"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/productright.png"
                  />
                </div>
              </div>
              <img
                class="pwm-pic2"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/wmshighone.png"
              />
            </div>
            <div class="pwm-main5 flex-row">
              <div
                class="pwm-box1 flex-col"
                :style="{ background: item.lanhuBg0 }"
                v-for="(item, index) in loopData0"
                :key="index"
              >
                <div
                  class="pwm-block3 flex-col"
                  :style="{ background: item.lanhuBg1 }"
                >
                  <div class="pwm-section2 flex-col">
                    <span class="pwm-info6" v-html="item.lanhutext0"></span>
                    <span class="pwm-txt10" v-html="item.lanhutext1"></span>
                    <img
                      v-if="item.slot2 === 2"
                      class="pwm-pic3"
                      referrerpolicy="no-referrer"
                      src="@/assets/main/productright.png"
                    />
                    <img
                      v-if="item.slot1 === 1"
                      class="pwm-pic4"
                      referrerpolicy="no-referrer"
                      :src="item.specialSlot1.lanhuimage0"
                    />
                  </div>
                </div>
                <img
                  class="pwm-img4"
                  referrerpolicy="no-referrer"
                  :src="item.lanhuimage0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="pwm-word9">多种业务&nbsp;&nbsp;&nbsp;全面把控</span>
      <span class="pwm-word10"
        >强大系统扩展性，使WMS、ERP、TMS等多个信息系统实现无缝对接，满足仓配一体化管理</span
      >
      <div class="pwm-bd7 flex-row">
        <div class="pwm-bd8 flex-col">
          <div class="pwm-bd9 flex-col">
            <span class="pwm-info7">大促业务</span>
            <div class="pwm-outer2 flex-row justify-between">
              <div class="pwm-mod26 flex-col"></div>
              <span class="pwm-info8">支持预打包</span>
            </div>
            <div class="pwm-outer3 flex-row justify-between">
              <div class="pwm-bd29 flex-col"></div>
              <span class="pwm-word11">活动计划设置</span>
            </div>
            <div class="pwm-outer4 flex-row justify-between">
              <div class="pwm-mod27 flex-col"></div>
              <span class="pwm-info9">爆款区域规划</span>
            </div>
          </div>
          <div class="pwm-bd10 flex-col"></div>
        </div>
        <!-- <div class="pwm-bd10 flex-col"></div> -->
        <div class="pwm-bd11 flex-col">
          <div class="pwm-mod5 flex-col">
            <span class="pwm-word12">第三方仓业务</span>
            <div class="pwm-wrap1 flex-row justify-between">
              <div class="pwm-main21 flex-col"></div>
              <span class="pwm-word13">建立虚拟仓</span>
            </div>
            <div class="pwm-wrap2 flex-row justify-between">
              <div class="pwm-box5 flex-col"></div>
              <span class="pwm-txt11">支持多货主混仓</span>
            </div>
            <div class="pwm-wrap3 flex-row justify-between">
              <div class="pwm-outer28 flex-col"></div>
              <span class="pwm-word14">多元费用结算条件</span>
            </div>
          </div>
          <div class="pwm-bd10 flex-col"></div>
        </div>
        <div class="pwm-bd13 flex-col">
          <div class="pwm-outer6 flex-row">
            <span class="pwm-word15">无人智能立库</span>
          </div>
          <div class="pwm-outer7 flex-row justify-between">
            <div class="pwm-section22 flex-col"></div>
            <span class="pwm-txt12">支持多种智能硬件标准接口</span>
          </div>
          <div class="pwm-outer8 flex-row justify-between">
            <div class="pwm-section23 flex-col"></div>
            <span class="pwm-txt13">多企业软件接口</span>
          </div>
          <div class="pwm-outer9 flex-row justify-between">
            <div class="pwm-block19 flex-col"></div>
            <span class="pwm-word16">完美提升出库效率</span>
          </div>
          <div class="pwm-bd10 flex-col"></div>
        </div>
      </div>
      <div class="pwm-bd14 flex-col">
        <div class="pwm-outer10 flex-row">
          <span class="pwm-txt14">核心功能</span>
        </div>
        <div class="pwm-outer11 flex-row">
          <div class="pwm-layer1 flex-col"></div>
        </div>
        <div class="pwm-outer12 flex-row justify-between">
          <img
            class="pwm-pic28"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscoreone.png"
          />
          <img
            class="pwm-img24"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscoretwo.png"
          />
          <img
            class="pwm-pic29"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscorethird.png"
          />
          <img
            class="pwm-img25"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscorefour.png"
          />
          <img
            class="pwm-pic30"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscorefive.png"
          />
        </div>
        <div class="pwm-outer13 flex-row justify-between">
          <span class="pwm-txt15">波次策略</span>
          <span class="pwm-word17">拣货策略</span>
          <span class="pwm-word18">盘点策略</span>
          <span class="pwm-info10">订单分类</span>
          <span class="pwm-info11">优选路径</span>
        </div>
        <div class="pwm-outer14 flex-row justify-between">
          <span class="pwm-word19"
            >手动波次、自动波次、截单波次等提高出库效率</span
          >
          <span class="pwm-txt16"
            >支持自动、手动拆合包裹，支持各种拆分发货</span
          >
          <span class="pwm-word20">支持多种盘点类型，支持外部触发盘点</span>
          <span class="pwm-infoBox1"
            >区分2B、2C处理流程，增加<br />连贯性，提高出库效率</span
          >
          <span class="pwm-info12">针对仓库提出最优拣货、补货路径</span>
        </div>
        <div class="pwm-outer15 flex-row justify-between">
          <img
            class="pwm-img26"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscoresix.png"
          />
          <img
            class="pwm-pic31"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscoreseven.png"
          />
          <img
            class="pwm-pic32"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscoreeight.png"
          />
          <img
            class="pwm-img27"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscorenine.png"
          />
          <img
            class="pwm-img28"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscoreten.png"
          />
        </div>
        <div class="pwm-outer16 flex-row">
          <span class="pwm-word21">异常管理</span>
          <span class="pwm-word22">库位可视化</span>
          <span class="pwm-info13">绩效管理</span>
          <span class="pwm-info14">多种报表</span>
          <span class="pwm-txt17">动销计算</span>
        </div>
        <div class="pwm-outer17 flex-row justify-between">
          <span class="pwm-word23"
            >对作业中收货、存储、质检等发生的残次品进行管理</span
          >
          <span class="pwm-word24">精准到每一库位，输出热力图，拣货不拥挤</span>
          <span class="pwm-word25"
            >对操作环节和业务进行绩效统计，覆盖所有业务</span
          >
          <span class="pwm-word26">可生成各类报表，助力业务人员所有场景</span>
          <span class="pwm-info15"
            >动态查询仓库库容，合理分配仓库有效利用率</span
          >
        </div>
        <div class="pwm-outer18 flex-row justify-between">
          <img
            class="pwm-pic33"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscore11.png"
          />
          <img
            class="pwm-pic34"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscore12.png"
          />
          <img
            class="pwm-pic35"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscore13.png"
          />
          <img
            class="pwm-pic36"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscore14.png"
          />
          <img
            class="pwm-pic37"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmscore15.png"
          />
        </div>
        <div class="pwm-outer19 flex-row">
          <span class="pwm-info16">支持多仓</span>
          <span class="pwm-word27">支持多货主</span>
          <span class="pwm-txt18">补货预警</span>
          <span class="pwm-word28">库存冻结</span>
          <span class="pwm-txt19">仓库管理</span>
        </div>
        <div class="pwm-outer20 flex-row justify-between">
          <span class="pwm-txt20"
            >对作业中收货、存储、质检等发生的残次品进行管理</span
          >
          <span class="pwm-word29">精准到每一库位，输出热力图，拣货不拥挤</span>
          <span class="pwm-word30"
            >对操作环节和业务进行绩效统计，覆盖所有业务</span
          >
          <span class="pwm-txt21">可生成各类报表，助力业务人员所有场景</span>
          <span class="pwm-word31"
            >动态查询仓库库容，合理分配仓库有效利用率</span
          >
        </div>
      </div>
      <span class="pwm-info17">产品流程</span>
      <span class="pwm-word32">灵活丰富的集成接口，可无缝快速对接多种系统</span>
      <div class="pwm-bd15 flex-col">
        <div class="pwm-bd16 flex-row justify-between">
          <!-- <div class="pwm-mod7 flex-col">
            <div class="pwm-main7 flex-col">
              <div class="pwm-section4 flex-col">
                <div class="pwm-block6 flex-col">
                  <span class="pwm-paragraph2">ERP/OMS<br />订单管理系统</span>
                </div>
                <div class="pwm-block7 flex-col"></div>
                <div class="pwm-block8 flex-col">
                  <span class="pwm-word33">开放平台标准接口</span>
                </div>
                <div class="pwm-block9 flex-row justify-between">
                  <div class="pwm-mod8 flex-col"></div>
                  <div class="pwm-mod9 flex-col"></div>
                </div>
                <div class="pwm-block10 flex-row justify-between">
                  <div class="pwm-section5 flex-col">
                    <span class="pwm-infoBox2"
                      >WCS<br />设备控制<br />系统</span
                    >
                  </div>
                  <div class="pwm-section6 flex-col">
                    <span class="pwm-paragraph3"
                      >TMS<br />运输管理<br />系统</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pwm-mod10 flex-col">
            <div class="pwm-group5 flex-row">
              <div class="pwm-layer2 flex-col justify-between">
                <img
                  class="pwm-icon1"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
                />
                <img
                  class="pwm-icon2"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2d1fffd7945e71da1c30e5001ccc5a4934eea0849d85fe83271628784ac4e6ac"
                />
              </div>
              <div class="pwm-layer3 flex-col justify-between">
                <img
                  class="pwm-label1"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
                />
                <img
                  class="pwm-icon3"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2d1fffd7945e71da1c30e5001ccc5a4934eea0849d85fe83271628784ac4e6ac"
                />
              </div>
              <img
                class="pwm-icon4"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
              />
              <img
                class="pwm-label2"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2d1fffd7945e71da1c30e5001ccc5a4934eea0849d85fe83271628784ac4e6ac"
              />
              <img
                class="pwm-label3"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
              />
              <img
                class="pwm-icon5"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2d1fffd7945e71da1c30e5001ccc5a4934eea0849d85fe83271628784ac4e6ac"
              />
              <div class="pwm-layer4 flex-col justify-between">
                <img
                  class="pwm-icon6"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2d1fffd7945e71da1c30e5001ccc5a4934eea0849d85fe83271628784ac4e6ac"
                />
                <img
                  class="pwm-icon7"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2d1fffd7945e71da1c30e5001ccc5a4934eea0849d85fe83271628784ac4e6ac"
                />
              </div>
              <div class="pwm-layer5 flex-col">
                <div class="pwm-box3 flex-col">
                  <div class="pwm-main8 flex-col">
                    <span class="pwm-word34">库内管理</span>
                  </div>
                  <div class="pwm-main9 flex-row justify-between">
                    <span class="pwm-txt22">补货策略</span>
                    <span class="pwm-info18">库存冻结</span>
                  </div>
                  <div class="pwm-main10 flex-row justify-between">
                    <span class="pwm-info19">补货预警</span>
                    <span class="pwm-word35">库存转移</span>
                  </div>
                  <div class="pwm-main11 flex-row justify-between">
                    <span class="pwm-word36">路径规划</span>
                    <span class="pwm-word37">库容库龄</span>
                  </div>
                  <div class="pwm-main12 flex-row justify-between">
                    <span class="pwm-word38">库存预警</span>
                    <span class="pwm-word39">热力分布</span>
                  </div>
                  <div class="pwm-main13 flex-row justify-between">
                    <span class="pwm-word40">盘点策略</span>
                    <span class="pwm-word41">数据可视</span>
                  </div>
                  <div class="pwm-main14 flex-row justify-between">
                    <span class="pwm-info20">库位管理</span>
                    <span class="pwm-word42">多重报表</span>
                  </div>
                  <div class="pwm-main15 flex-row justify-between">
                    <span class="pwm-word43">异常处理</span>
                    <span class="pwm-txt23">人员绩效</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pwm-group6 flex-col">
              <div class="pwm-bd17 flex-row">
                <div class="pwm-section7 flex-col">
                  <span class="pwm-info21">入库管理</span>
                </div>
                <div class="pwm-section8 flex-col justify-between">
                  <img
                    class="pwm-icon21"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngee561dc28db19f4606cb0a187bab428e1291acabe990b3d8b9fdd368b44cabe8"
                  />
                  <span class="pwm-infoBox3">采购订单<br />销退接收</span>
                </div>
                <div class="pwm-section9 flex-col justify-between">
                  <img
                    class="pwm-icon22"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7e4d9ca1a77410cbb8ecd7c95bec02c06abb5adf2c03606c87cb6af2cd3b5aee"
                  />
                  <span class="pwm-word44">到货预约</span>
                </div>
                <div class="pwm-section10 flex-col justify-between">
                  <img
                    class="pwm-icon23"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngffd251b956d31c4ee714c2d4d40c00c4ea78e7902f8406169f2aaacb65540520"
                  />
                  <span class="pwm-word45">收货</span>
                </div>
                <div class="pwm-section11 flex-col justify-between">
                  <img
                    class="pwm-label17"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf83272753ea9ce383e10b9d93c9387016ec230de645ec94f7061e8cd44be2d72"
                  />
                  <span class="pwm-txt24">质检</span>
                </div>
                <div class="pwm-section12 flex-col justify-between">
                  <img
                    class="pwm-label18"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng577ed8026aeade12bb0c83088b294fba5f954dbd59d86a84e7130651f7df9bb3"
                  />
                  <span class="pwm-info22">上架</span>
                </div>
              </div>
            </div>
            <div class="pwm-group7 flex-col"></div>
            <div class="pwm-group8 flex-col"></div>
            <div class="pwm-group9 flex-col">
              <div class="pwm-section13 flex-row">
                <div class="pwm-outer21 flex-col">
                  <span class="pwm-info23">出库管理</span>
                </div>
                <div class="pwm-outer22 flex-col justify-between">
                  <img
                    class="pwm-icon24"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3a3cd411b202232de00ea3953a4382182f8010c2f65865d63393226fbd94ca0b"
                  />
                  <span class="pwm-word46">集包</span>
                </div>
                <div class="pwm-outer23 flex-col justify-between">
                  <img
                    class="pwm-icon25"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc107014001fd9c4b33b3d9c069846e720fb2ba1312750755e2f85be88ff97cc7"
                  />
                  <span class="pwm-infoBox4">单品复核<br />多品复核</span>
                </div>
                <div class="pwm-outer24 flex-col">
                  <div class="pwm-section14 flex-col justify-between">
                    <span class="pwm-word47">边拣边播</span>
                    <span class="pwm-info24">二次分拣</span>
                  </div>
                </div>
                <div class="pwm-outer25 flex-col justify-between">
                  <img
                    class="pwm-icon26"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9bec83d7e56696324803b88e873e7d9cf4dd58f0c95924d9842510389cd3ef72"
                  />
                  <span class="pwm-word48">拣货</span>
                </div>
                <div class="pwm-outer26 flex-col justify-between">
                  <img
                    class="pwm-label19"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngee39411023b7677222bc766f6226b37f50ce69aa4192a73707fb12ad77fb8be9"
                  />
                  <span class="pwm-word49">拣货策略</span>
                </div>
                <div class="pwm-outer27 flex-col justify-between">
                  <img
                    class="pwm-label20"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga66ff1f87d600d2774243f72862d773632d8e42467bf368b05404ac96f5379a4"
                  />
                  <span class="pwm-word50">波次策略</span>
                </div>
              </div>
              <div class="pwm-section15 flex-row">
                <img
                  class="pwm-icon27"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0af1c6d59f3077e8e371526dbe8d1c577ee9e65d61528502c29b9befe42cf5d7"
                />
                <img
                  class="pwm-label21"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng30d121d351c1fc8af95d2b6d54c68b80538b8c8d309fcb6e134ed95939d67e56"
                />
                <img
                  class="pwm-icon28"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2adda020772525f02ecc71322464876d677fb078aa8488a57d7f3b6a5c6face4"
                />
              </div>
              <div class="pwm-section16 flex-row">
                <span class="pwm-txt25">并行装箱</span>
                <span class="pwm-word51">多人拣货</span>
                <span class="pwm-word52">大单任务拆分</span>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <img
          class="pwm-pic13"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga0bcb0675cf02461d54d08ba4542bdec16b468b5d728d3350f0d01352db752ce"
        /> -->
      </div>
      <span class="pwm-word53">典型案例</span>
      <div class="pwm-bd18 flex-row justify-between">
        <div class="pwm-mod11 flex-col">
          <img
            class="pwm-pic14"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/wmsinsone.png"
          />
        </div>
        <div class="pwm-mod12 flex-col">
          <span class="pwm-txt26">SONY-中国&nbsp;无人智能立库</span>
          <span class="pwm-txt27">主要经营各种数码产品，电商类</span>
          <div class="pwm-mod13 flex-col"></div>
          <span class="pwm-word54">痛点：</span>
          <span class="pwm-paragraph4"
            >1.在原系统基础上作业（拣货、出库）效率再提升<br />2.在货位存储量有限不打算扩建仓库的情况下，货品太多，仓库存储位不够</span
          >
          <span class="pwm-word55">解决方案：</span>
          <span class="pwm-paragraph5"
            >1.由传统“人到货”方式变为“货到人”方式，提升作业效率、降低差错率<br />2.采用大型立库，货位翻倍，可存储量翻倍，并配有两台一体机，仓库操作员无需即可完成各种任务，节省人力</span
          >
          <div class="pwm-mod14 flex-row">
            <span class="pwm-info25">作业效率</span>
            <div class="pwm-mod15 flex-col"></div>
            <span class="pwm-word56">-30%</span>
            <span class="pwm-word57">差错率</span>
            <div class="pwm-mod16 flex-col"></div>
          </div>
          <button class="pwm-mod17 flex-col" @click="jumpPage('instance','header-ident')">
            <span class="pwm-info26">查看详情</span>
          </button>
        </div>
      </div>
      <secFoot />
      <footInfo />
    </div>
    <div class="pwm-block13 flex-col">
      <div class="pwm-mod24 flex-col">
        <span class="pwm-word68">仓内业务</span>
        <div class="pwm-section17 flex-row justify-between">
          <div class="pwm-wrap8 flex-col"></div>
          <span class="pwm-word69">仓内智能补货</span>
        </div>
        <div class="pwm-section18 flex-row justify-between">
          <div class="pwm-layer8 flex-col"></div>
          <span class="pwm-info34">系统指引上架</span>
        </div>
        <div class="pwm-section19 flex-row justify-between">
          <div class="pwm-box6 flex-col"></div>
          <span class="pwm-word70">跨楼层移库任务</span>
        </div>
      </div>
          <div class="pwm-bd10 flex-col"></div>
    </div>
    <span class="pwm-info35">60%</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 1,
      loopData0: [
        {
          // lanhuBg0:
          //   "url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngee188c1c70ee5aac50002cb91c8460433eb6d80c70629a544b568949573f5762) 100% no-repeat",
          lanhuBg1:
            "url("+require('../../assets/images/back/wmsgreyone.png')+") 100% no-repeat",
          lanhutext0: "精准算法",
          lanhutext1:
            "拣货、波次、盘点策略<br/>补货、过期预警<br/>先进先出、最优拣货路径明确",
          lanhuimage0:
            require('../../assets/images/procedure/wmshightwo.png'),
          specialSlot1: {
            lanhuimage0:
              require("@/assets/main/productright.png"),
          },
          slot1: 1,
        },
        {
          // lanhuBg0:
          //   "url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngee188c1c70ee5aac50002cb91c8460433eb6d80c70629a544b568949573f5762) 100% no-repeat",
          lanhuBg1:
            "url("+require('../../assets/images/back/wmsgreytwo.png')+") 100% no-repeat",
          lanhutext0: "智能硬件",
          lanhutext1: "多种报表明细<br/>减轻工作负担<br/>数据一目了然",
          lanhuimage0:
            require('../../assets/images/procedure/wmshighthird.png'),
          slot2: 2,
        },
        {
          // lanhuBg0:
          //   "url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5c27e9463653d3506d8733abeee3835620e9e4bf557063c0de50c7ac3773d196) 100% no-repeat",
          lanhuBg1:
            "url("+require('../../assets/images/back/wmsgreythird.png')+") 100% no-repeat",
          lanhutext0: "数据可视化",
          lanhutext1: "库位可视化<br/>库位热度可视化<br/>操作员绩效可视化",
          lanhuimage0:
            require('../../assets/images/procedure/wmshighfour.png'),
          specialSlot1: {
            lanhuimage0:
              require("@/assets/main/productright.png"),
          },
          slot1: 1,
        },
      ],
      constants: {},
    };
  },
  methods: {
    jumpPage(path,id) {
      var path=path
      var Id=id;
      localStorage.setItem('toId',Id);
      this.$router.push({
        name: path,
        params: {
          sel: 'first'
        }
      });
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        case 1: // 产品介绍
          url = "/product/erp";
          break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance/erp";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.pwm-page {
  position: relative;
  width: 1920px;
  height: 7886px;
  margin: 0 auto;
  background-color: rgba(250, 250, 250, 1);
  overflow: hidden;
  .pwm-block1 {
    width: 1920px;
    height: 7886px;
    .pwm-bd1 {
      // z-index: 13;
      height: 920px;
      overflow: hidden;
      // background: url(../../assets/images/back/graythird.png)
      //   100% no-repeat;
      width: 1920px;
      position: relative;
      .pwm-mod1 {
        z-index: 80;
        width: 1920px;
        height: 610px;
        .pwm-group1 {
          height: 100px;
          // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4fabcf914c36d5ea3cd07d6ce078e0458ab67025b5599f23ef90745c2e46e937)
          //     100% no-repeat;
          width: 1920px;
          .pwm-bd2 {
            width: 1775px;
            height: 42px;
            margin: 29px 0 0 65px;
            .pwm-img1 {
              width: 180px;
              height: 38px;
              margin-top: 2px;
            }
            .pwm-word1 {
              width: 43px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 301px;
            }
            .pwm-txt1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiB;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pwm-txt2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pwm-info1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pwm-word2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pwm-section1 {
              height: 42px;
              border-radius: 21px;
              border: 2px solid rgba(37, 184, 47, 1);
              margin-left: 346px;
              width: 149px;
              .pwm-info2 {
                width: 85px;
                height: 29px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 0.94);
                font-size: 21px;
                letter-spacing: 0.41999998688697815px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 29px;
                text-align: left;
                margin: 7px 0 0 32px;
              }
            }
          }
        }
        .pwm-txt3 {
          width: 358px;
          height: 65px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 48px;
          letter-spacing: 0.9599999785423279px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 65px;
          text-align: left;
          margin: 221px 0 0 781px;
        }
        .pwm-word3 {
          width: 850px;
          height: 38px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 28px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 38px;
          text-align: left;
          margin: 36px 0 0 535px;
        }
        .pwm-group2 {
          height: 68px;
          border-radius: 34px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.32);
          width: 220px;
          margin: 82px 0 0 850px;
          .pwm-txt4 {
            width: 113px;
            height: 38px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 28px;
            letter-spacing: 0.5600000023841858px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 38px;
            text-align: left;
            margin: 15px 0 0 54px;
          }
        }
      }
      .pwm-mod2 {
        z-index: 14;
        height: 920px;
        background: url(../../assets/main/wms.png)
          100% no-repeat;
        width: 1920px;
        position: absolute;
        left: 0;
        top: 0;
        .pwm-outer1 {
          width: 1920px;
          height: 920px;
          // background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .pwm-txt5 {
      width: 129px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(39, 39, 39, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 896px;
    }
    .pwm-word4 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .pwm-bd3 {
      width: 1221px;
      height: 128px;
      margin: 85px 0 0 343px;
      .pwm-img22 {
        width: 152px;
        height: 118px;
        margin-top: 3px;
      }
      .pwm-img23 {
        width: 126px;
        height: 126px;
        margin-left: 221px;
      }
      .pwm-main19 {
        width: 126px;
        height: 128px;
        background: url(../../assets/images/procedure/wmsthird.png)
          0px -1px no-repeat;
        margin-left: 235px;
      }
      .pwm-main20 {
        width: 130px;
        height: 121px;
        background: url(../../assets/images/procedure/wmsfour.png) -1px
          0px no-repeat;
        margin: 3px 0 0 231px;
      }
    }
    .pwm-bd4 {
      width: 1165px;
      height: 29px;
      margin: 47px 0 0 376px;
      .pwm-txt6 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
      .pwm-word5 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
      .pwm-txt7 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: center;
      }
      .pwm-word6 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
    }
    .pwm-bd5 {
      width: 1325px;
      height: 56px;
      margin: 16px 0 0 296px;
      .pwm-word7 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pwm-txt8 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pwm-info3 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pwm-word8 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .pwm-bd6 {
      height: 946px;
      background-color: rgba(37, 38, 37, 1);
      margin-top: 120px;
      width: 1920px;
      .pwm-main1 {
        width: 1920px;
        height: 810px;
        margin-top: 68px;
        .pwm-info4 {
          width: 129px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 32px;
          letter-spacing: 0.6399999856948853px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 44px;
          text-align: left;
          margin-left: 896px;
        }
        .pwm-info5 {
          width: 615px;
          height: 32px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          letter-spacing: 1.5px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 32px;
          text-align: center;
          margin: 24px 0 0 653px;
        }
        .pwm-main2 {
          width: 84px;
          height: 6px;
          background-color: rgba(37, 184, 47, 1);
          margin: 12px 0 0 919px;
        }
        .pwm-main3 {
          width: 1920px;
          height: 644px;
          margin-top: 48px;
          .pwm-main4 {
            // z-index: 142;
            height: 644px;
            overflow: hidden;
            // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng12f6cacfdcdc4952f1d443763825efc0e051d671bbabef66164bb6cf123dbf80)
            //   100% no-repeat;
            width: 688px;
            position: relative;
            .pwm-block2 {
              z-index: 1;
              height: 644px;
              overflow: hidden;
              background: url(../../assets/images/back/wmsgreyfour.png)
                100% no-repeat;
              width: 688px;
              position: relative;
              .pwm-group3 {
                width: 245px;
                height: 257px;
                margin: 248px 0 0 120px;
                .pwm-txt9 {
                  width: 181px;
                  height: 50px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 36px;
                  letter-spacing: 0.7200000286102295px;
                  font-family: AlibabaPuHuiTiM;
                  white-space: nowrap;
                  line-height: 50px;
                  text-align: left;
                }
                .pwm-paragraph1 {
                  width: 245px;
                  height: 84px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 28px;
                  text-align: left;
                  margin-top: 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .pwm-img3 {
                  width: 55px;
                  height: 55px;
                  margin-top: 52px;
                }
              }
            }
            .pwm-pic2 {
              // z-index: 143;
              position: absolute;
              left: 0;
              top: 0;
              width: 688px;
              height: 644px;
            }
          }
          .pwm-main5 {
            width: 1232px;
            height: 644px;
            justify-content: space-between;
            .pwm-box1 {
              z-index: 1;
              height: 644px;
              overflow: hidden;
              width: 411px;
              position: relative;
              .pwm-block3 {
                z-index: 2;
                height: 644px;
                overflow: hidden;
                width: 411px;
                position: relative;
                .pwm-section2 {
                  width: 245px;
                  height: 257px;
                  margin: 248px 0 0 80px;
                  .pwm-info6 {
                    width: 181px;
                    height: 50px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 36px;
                    letter-spacing: 0.7200000286102295px;
                    font-family: AlibabaPuHuiTiM;
                    white-space: nowrap;
                    line-height: 50px;
                    text-align: left;
                  }
                  .pwm-txt10 {
                    width: 245px;
                    height: 84px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    letter-spacing: 1.5px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 28px;
                    text-align: left;
                    margin-top: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  .pwm-pic3 {
                    width: 55px;
                    height: 55px;
                    margin: 52px 0 0 25px;
                  }
                  .pwm-pic4 {
                    width: 55px;
                    height: 55px;
                    margin-top: 52px;
                  }
                }
              }
              .pwm-img4 {
                // z-index: 147;
                position: absolute;
                left: 0;
                top: 0;
                width: 411px;
                height: 644px;
              }
            }
          }
        }
      }
    }
    .pwm-word9 {
      width: 284px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: center;
      margin: 120px 0 0 810px;
    }
    .pwm-word10 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .pwm-bd7 {
      width: 1520px;
      height: 408px;
      margin: 52px 0 0 201px;
      .pwm-bd8 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 359px;
        .pwm-bd9 {
          width: 133px;
          height: 201px;
          margin: 92px 0 0 116px;
          .pwm-info7 {
            width: 97px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: left;
            margin-left: 15px;
          }
          .pwm-outer2 {
            width: 116px;
            height: 28px;
            margin-top: 36px;
            .pwm-mod26 {
              width: 22px;
              height: 21px;
              background: url(../../assets/images/procedure/tick.png)
                0px 0px no-repeat;
              margin-top: 4px;
            }
            .pwm-info8 {
              width: 86px;
              height: 28px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(102, 102, 102, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 28px;
              text-align: center;
            }
          }
          .pwm-outer3 {
            width: 133px;
            height: 28px;
            margin-top: 24px;
            .pwm-bd29 {
              width: 22px;
              height: 21px;
              background: url(../../assets/images/procedure/tick.png)
                0px 0px no-repeat;
              margin-top: 4px;
            }
            .pwm-word11 {
              width: 103px;
              height: 28px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(102, 102, 102, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 28px;
              text-align: center;
            }
          }
          .pwm-outer4 {
            width: 133px;
            height: 28px;
            margin-top: 24px;
            .pwm-mod27 {
              width: 22px;
              height: 22px;
              background: url(../../assets/images/procedure/tick.png)
                0px 0px no-repeat;
              margin-top: 4px;
            }
            .pwm-info9 {
              width: 103px;
              height: 28px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(102, 102, 102, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 28px;
              text-align: center;
            }
          }
        }
      }
      .pwm-bd8:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .pwm-info7 {
          color: rgba(255, 255, 255, 1);
        }
        .pwm-bd9 .pwm-info8,
        .pwm-outer3 .pwm-word11,
        .pwm-outer4 .pwm-info9 {
          color: rgba(255, 255, 255, 1);
        }
        .pwm-bd9 .pwm-mod26,
        .pwm-outer3 .pwm-bd29,
        .pwm-outer4 .pwm-mod27 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .pwm-bd10 {
          // width: 145px;
          // height: 25px;
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: 20px 0 0 56px;
        }
      }
      .pwm-bd11 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        margin-left: 416px;
        width: 358px;
        .pwm-mod5 {
          width: 167px;
          height: 201px;
          margin: 92px 0 0 102px;
          .pwm-word12 {
            width: 145px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: left;
            margin-left: 5px;
          }
          .pwm-wrap1 {
            width: 116px;
            height: 28px;
            margin-top: 36px;
            .pwm-main21 {
              width: 21px;
              height: 21px;
              background: url(../../assets/images/procedure/tick.png)
                0px 0px no-repeat;
              margin-top: 4px;
            }
            .pwm-word13 {
              width: 86px;
              height: 28px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(102, 102, 102, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 28px;
              text-align: center;
            }
          }
          .pwm-wrap2 {
            width: 150px;
            height: 28px;
            margin-top: 24px;
            .pwm-box5 {
              width: 21px;
              height: 21px;
              background: url(../../assets/images/procedure/tick.png)
                0px 0px no-repeat;
              margin-top: 4px;
            }
            .pwm-txt11 {
              width: 120px;
              height: 28px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(102, 102, 102, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 28px;
              text-align: center;
            }
          }
          .pwm-wrap3 {
            width: 167px;
            height: 28px;
            margin-top: 24px;
            .pwm-outer28 {
              width: 21px;
              height: 22px;
              background: url(../../assets/images/procedure/tick.png)
                0px 0px no-repeat;
              margin-top: 4px;
            }
            .pwm-word14 {
              width: 137px;
              height: 28px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(102, 102, 102, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 28px;
              text-align: center;
            }
          }
        }
      }
      .pwm-bd11:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .pwm-word12 {
          color: rgba(255, 255, 255, 1);
        }
        .pwm-wrap1 .pwm-word13,
        .pwm-wrap2 .pwm-txt11,
        .pwm-wrap3 .pwm-word14 {
          color: rgba(255, 255, 255, 1);
        }
        .pwm-wrap1 .pwm-main21,
        .pwm-wrap2 .pwm-box5,
        .pwm-wrap3 .pwm-outer28 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .pwm-bd10 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: 20px 0 0 56px;
        }
      }
      .pwm-bd13 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        margin-left: 29px;
        width: 358px;
        .pwm-outer6 {
          width: 145px;
          height: 33px;
          margin: 92px 0 0 107px;
          .pwm-word15 {
            width: 145px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: left;
          }
        }
        .pwm-outer7 {
          width: 235px;
          height: 28px;
          margin: 36px 0 0 64px;
          .pwm-section22 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .pwm-txt12 {
            width: 205px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .pwm-outer8 {
          width: 150px;
          height: 28px;
          margin: 24px 0 0 64px;
          .pwm-section23 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .pwm-txt13 {
            width: 120px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .pwm-outer9 {
          width: 167px;
          height: 28px;
          margin: 24px 0 103px 64px;
          .pwm-block19 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .pwm-word16 {
            width: 137px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
      }
      .pwm-bd13:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .pwm-word15 {
          color: rgba(255, 255, 255, 1);
        }
        .pwm-outer7 .pwm-txt12,
        .pwm-outer8 .pwm-txt13,
        .pwm-outer9 .pwm-word16 {
          color: rgba(255, 255, 255, 1);
        }
        .pwm-outer7 .pwm-section22,
        .pwm-outer8 .pwm-section23,
        .pwm-outer9 .pwm-block19 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .pwm-bd10 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: -83px 0 0 56px;
        }
      }
    }
    .pwm-bd14 {
      height: 1109px;
      background-color: rgba(37, 184, 47, 1);
      margin-top: 108px;
      width: 1920px;
      .pwm-outer10 {
        width: 129px;
        height: 44px;
        margin: 80px 0 0 896px;
        .pwm-txt14 {
          width: 129px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 32px;
          letter-spacing: 0.6399999856948853px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 44px;
          text-align: left;
        }
      }
      .pwm-outer11 {
        width: 84px;
        height: 6px;
        margin: 24px 0 0 918px;
        .pwm-layer1 {
          width: 84px;
          height: 6px;
          background-color: rgba(255, 255, 255, 1);
        }
      }
      .pwm-outer12 {
        width: 1111px;
        height: 120px;
        margin: 76px 0 0 404px;
        .pwm-pic28 {
          width: 120px;
          height: 120px;
        }
        .pwm-img24 {
          width: 120px;
          height: 120px;
        }
        .pwm-pic29 {
          width: 120px;
          height: 120px;
        }
        .pwm-img25 {
          width: 120px;
          height: 120px;
        }
        .pwm-pic30 {
          width: 120px;
          height: 120px;
        }
      }
      .pwm-outer13 {
        width: 1060px;
        height: 28px;
        margin: 20px 0 0 430px;
        .pwm-txt15 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .pwm-word17 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .pwm-word18 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .pwm-info10 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .pwm-info11 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
      }
      .pwm-outer14 {
        width: 1179px;
        height: 44px;
        margin: 3px 0 0 370px;
        .pwm-word19 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-txt16 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-word20 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-infoBox1 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-info12 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .pwm-outer15 {
        width: 1111px;
        height: 120px;
        margin: 56px 0 0 404px;
        .pwm-img26 {
          width: 120px;
          height: 120px;
        }
        .pwm-pic31 {
          width: 120px;
          height: 120px;
        }
        .pwm-pic32 {
          width: 120px;
          height: 120px;
        }
        .pwm-img27 {
          width: 120px;
          height: 120px;
        }
        .pwm-img28 {
          width: 120px;
          height: 120px;
        }
      }
      .pwm-outer16 {
        width: 1060px;
        height: 28px;
        margin: 20px 0 0 430px;
        .pwm-word21 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .pwm-word22 {
          width: 86px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 171px;
        }
        .pwm-info13 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 169px;
        }
        .pwm-info14 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 179px;
        }
        .pwm-txt17 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 179px;
        }
      }
      .pwm-outer17 {
        width: 1180px;
        height: 44px;
        margin: 3px 0 0 370px;
        .pwm-word23 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-word24 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-word25 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-word26 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-info15 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .pwm-outer18 {
        width: 1111px;
        height: 120px;
        margin: 56px 0 0 404px;
        .pwm-pic33 {
          width: 120px;
          height: 120px;
        }
        .pwm-pic34 {
          width: 120px;
          height: 120px;
        }
        .pwm-pic35 {
          width: 120px;
          height: 120px;
        }
        .pwm-pic36 {
          width: 120px;
          height: 120px;
        }
        .pwm-pic37 {
          width: 120px;
          height: 120px;
        }
      }
      .pwm-outer19 {
        width: 1060px;
        height: 28px;
        margin: 20px 0 0 430px;
        .pwm-info16 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .pwm-word27 {
          width: 86px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 171px;
        }
        .pwm-txt18 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 169px;
        }
        .pwm-word28 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 179px;
        }
        .pwm-txt19 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 179px;
        }
      }
      .pwm-outer20 {
        width: 1180px;
        height: 44px;
        margin: 3px 0 122px 370px;
        .pwm-txt20 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-word29 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-word30 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-txt21 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-word31 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .pwm-info17 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 893px;
    }
    .pwm-word32 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .pwm-bd15 {
      z-index: 9;
      height: 822px;
      background-color: rgba(255, 255, 255, 1);
      margin-top: 36px;
      width: 1920px;
      position: relative;
      .pwm-bd16 {
        width: 1355px;
        height: 540px;
        margin: 128px 0 0 283px;
        background: url(../../assets/images/procedure/wmsflow.png) 
          100% no-repeat;
        .pwm-mod7 {
          height: 540px;
          background-color: rgba(0, 255, 17, 0.05);
          width: 278px;
          .pwm-main7 {
            height: 500px;
            border: 1px solid rgba(37, 184, 47, 1);
            width: 230px;
            margin: 20px 0 0 24px;
            .pwm-section4 {
              width: 173px;
              height: 358px;
              margin: 73px 0 0 26px;
              .pwm-block6 {
                height: 80px;
                background-color: rgba(0, 255, 17, 0.16);
                width: 173px;
                .pwm-paragraph2 {
                  width: 103px;
                  height: 48px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiR;
                  line-height: 24px;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 16px 0 0 35px;
                }
              }
              .pwm-block7 {
                width: 1px;
                height: 60px;
                background: url(../../assets/images/procedure/greenarrow.png) -4px -1px
                  no-repeat;
                margin-left: 86px;
              }
              .pwm-block8 {
                height: 60px;
                background-color: rgba(37, 184, 47, 1);
                width: 173px;
                .pwm-word33 {
                  width: 137px;
                  height: 32px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 32px;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 14px 0 0 18px;
                }
              }
              .pwm-block9 {
                width: 89px;
                height: 60px;
                margin-left: 42px;
                .pwm-mod8 {
                  width: 1px;
                  height: 60px;
                  background: url(../../assets/images/procedure/greenarrow.png) -4px -1px
                    no-repeat;
                }
                .pwm-mod9 {
                  width: 1px;
                  height: 60px;
                  background: url(../../assets/images/procedure/greenarrow.png) -4px -1px
                    no-repeat;
                }
              }
              .pwm-block10 {
                width: 173px;
                height: 96px;
                margin-top: 2px;
                .pwm-section5 {
                  height: 96px;
                  background-color: rgba(0, 255, 17, 0.16);
                  width: 85px;
                  .pwm-infoBox2 {
                    width: 69px;
                    height: 72px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiR;
                    line-height: 24px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 12px 0 0 8px;
                  }
                }
                .pwm-section6 {
                  height: 96px;
                  background-color: rgba(0, 255, 17, 0.16);
                  width: 85px;
                  .pwm-paragraph3 {
                    width: 69px;
                    height: 72px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiR;
                    line-height: 24px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 12px 0 0 8px;
                  }
                }
              }
            }
          }
        }
        .pwm-mod10 {
          z-index: 418;
          height: 540px;
          background-color: rgba(0, 255, 17, 0.05);
          width: 1009px;
          position: relative;
          .pwm-group5 {
            width: 786px;
            height: 484px;
            margin: 28px 0 0 199px;
            .pwm-layer2 {
              width: 10px;
              height: 218px;
              margin-top: 58px;
              .pwm-icon1 {
                width: 10px;
                height: 14px;
              }
              .pwm-icon2 {
                width: 10px;
                height: 14px;
                margin-top: 190px;
              }
            }
            .pwm-layer3 {
              width: 10px;
              height: 218px;
              margin: 58px 0 0 78px;
              .pwm-label1 {
                width: 10px;
                height: 14px;
              }
              .pwm-icon3 {
                width: 10px;
                height: 14px;
                margin-top: 190px;
              }
            }
            .pwm-icon4 {
              width: 10px;
              height: 14px;
              margin: 58px 0 0 78px;
            }
            .pwm-label2 {
              width: 10px;
              height: 14px;
              margin: 262px 0 0 24px;
            }
            .pwm-label3 {
              width: 10px;
              height: 14px;
              margin: 58px 0 0 45px;
            }
            .pwm-icon5 {
              width: 10px;
              height: 14px;
              margin: 262px 0 0 23px;
            }
            .pwm-layer4 {
              width: 10px;
              height: 140px;
              margin: 262px 0 0 78px;
              .pwm-icon6 {
                width: 10px;
                height: 14px;
              }
              .pwm-icon7 {
                width: 10px;
                height: 14px;
                margin-top: 112px;
              }
            }
            .pwm-layer5 {
              height: 484px;
              border: 1px solid rgba(37, 184, 47, 1);
              margin-left: 141px;
              width: 249px;
              .pwm-box3 {
                width: 181px;
                height: 405px;
                margin: 20px 0 0 31px;
                .pwm-main8 {
                  height: 40px;
                  border-radius: 8px;
                  // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga50cdbf8394c9552eef1c65027620fd9e9f181fbc4e892392a1e023220b2035d)
                  //   100% no-repeat;
                  margin-left: 38px;
                  width: 112px;
                  .pwm-word34 {
                    width: 69px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin: 8px 0 0 21px;
                  }
                }
                .pwm-main9 {
                  width: 181px;
                  height: 25px;
                  margin-top: 28px;
                  .pwm-txt22 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 1px;
                  }
                  .pwm-info18 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                  }
                }
                .pwm-main10 {
                  width: 181px;
                  height: 25px;
                  margin-top: 27px;
                  .pwm-info19 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 1px;
                  }
                  .pwm-word35 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                  }
                }
                .pwm-main11 {
                  width: 181px;
                  height: 25px;
                  margin-top: 27px;
                  .pwm-word36 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 1px;
                  }
                  .pwm-word37 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                  }
                }
                .pwm-main12 {
                  width: 181px;
                  height: 25px;
                  margin-top: 27px;
                  .pwm-word38 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 1px;
                  }
                  .pwm-word39 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                  }
                }
                .pwm-main13 {
                  width: 181px;
                  height: 24px;
                  margin-top: 28px;
                  .pwm-word40 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                  }
                  .pwm-word41 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                  }
                }
                .pwm-main14 {
                  width: 181px;
                  height: 24px;
                  margin-top: 28px;
                  .pwm-info20 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                  }
                  .pwm-word42 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                  }
                }
                .pwm-main15 {
                  width: 181px;
                  height: 24px;
                  margin-top: 28px;
                  .pwm-word43 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                  }
                  .pwm-txt23 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                  }
                }
              }
            }
          }
          .pwm-group6 {
            z-index: 429;
            height: 178px;
            border: 1px solid rgba(37, 184, 47, 1);
            width: 688px;
            position: absolute;
            left: 24px;
            top: 28px;
            .pwm-bd17 {
              width: 488px;
              height: 112px;
              margin: 40px 0 0 24px;
              .pwm-section7 {
                height: 112px;
                border-radius: 8px;
                background-color: rgba(0, 255, 17, 0.16);
                width: 40px;
                .pwm-info21 {
                  width: 16px;
                  height: 96px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiR;
                  line-height: 24px;
                  text-align: left;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 8px 0 0 12px;
                }
              }
              .pwm-section8 {
                width: 60px;
                height: 102px;
                margin-left: 42px;
                .pwm-icon21 {
                  width: 48px;
                  height: 48px;
                  margin-left: 6px;
                }
                .pwm-infoBox3 {
                  width: 60px;
                  height: 48px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  line-height: 24px;
                  text-align: left;
                  margin-top: 6px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .pwm-section9 {
                width: 60px;
                height: 78px;
                margin-left: 28px;
                .pwm-icon22 {
                  width: 48px;
                  height: 48px;
                  margin-left: 6px;
                }
                .pwm-word44 {
                  width: 60px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: left;
                  margin-top: 6px;
                }
              }
              .pwm-section10 {
                width: 48px;
                height: 78px;
                margin-left: 34px;
                .pwm-icon23 {
                  width: 48px;
                  height: 48px;
                }
                .pwm-word45 {
                  width: 30px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: left;
                  margin: 6px 0 0 9px;
                }
              }
              .pwm-section11 {
                width: 48px;
                height: 78px;
                margin-left: 40px;
                .pwm-label17 {
                  width: 48px;
                  height: 48px;
                }
                .pwm-txt24 {
                  width: 30px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: left;
                  margin: 6px 0 0 9px;
                }
              }
              .pwm-section12 {
                width: 48px;
                height: 78px;
                margin-left: 40px;
                .pwm-label18 {
                  width: 48px;
                  height: 48px;
                }
                .pwm-info22 {
                  width: 30px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: left;
                  margin: 6px 0 0 9px;
                }
              }
            }
          }
          .pwm-group7 {
            z-index: 599;
            position: absolute;
            left: 686px;
            top: 101px;
            width: 63px;
            height: 43px;
            // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge7c651c9fd813acf33e0313c2788a4820cce60b84b64878cf2f2baf748ec5f09)
            //   0px 0px no-repeat;
          }
          .pwm-group8 {
            z-index: 602;
            position: absolute;
            left: 686px;
            top: 346px;
            width: 63px;
            height: 43px;
            // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0db98c59d984c6e0e872bc24bf27803547401f0ca6b928a968939ec4945e3bad)
            //   0px 0px no-repeat;
          }
          .pwm-group9 {
            z-index: 431;
            height: 280px;
            border: 1px solid rgba(37, 184, 47, 1);
            width: 688px;
            position: absolute;
            left: 24px;
            top: 232px;
            .pwm-section13 {
              width: 615px;
              height: 124px;
              margin: 28px 0 0 24px;
              .pwm-outer21 {
                height: 112px;
                border-radius: 8px;
                background-color: rgba(255, 200, 26, 0.2);
                margin-top: 12px;
                width: 40px;
                .pwm-info23 {
                  width: 16px;
                  height: 96px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiR;
                  line-height: 24px;
                  text-align: left;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 8px 0 0 12px;
                }
              }
              .pwm-outer22 {
                width: 48px;
                height: 78px;
                margin: 12px 0 0 48px;
                .pwm-icon24 {
                  width: 48px;
                  height: 48px;
                }
                .pwm-word46 {
                  width: 30px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: left;
                  margin: 6px 0 0 9px;
                }
              }
              .pwm-outer23 {
                width: 60px;
                height: 102px;
                margin: 12px 0 0 34px;
                .pwm-icon25 {
                  width: 48px;
                  height: 48px;
                  margin-left: 6px;
                }
                .pwm-infoBox4 {
                  width: 60px;
                  height: 48px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  line-height: 24px;
                  text-align: left;
                  margin-top: 6px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .pwm-outer24 {
                height: 72px;
                // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng576db48a89f5b025ebd54e82b929f02ea2c9b6ea174c081350cbc7410bfe38ac) -1px -1px
                //   no-repeat;
                margin-left: 34px;
                width: 81px;
                .pwm-section14 {
                  width: 60px;
                  height: 54px;
                  margin: 9px 0 0 10px;
                  .pwm-word47 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                  }
                  .pwm-info24 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 6px;
                  }
                }
              }
              .pwm-outer25 {
                width: 48px;
                height: 78px;
                margin: 12px 0 0 40px;
                .pwm-icon26 {
                  width: 48px;
                  height: 48px;
                }
                .pwm-word48 {
                  width: 30px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                  margin: 6px 0 0 9px;
                }
              }
              .pwm-outer26 {
                width: 60px;
                height: 78px;
                margin: 12px 0 0 34px;
                .pwm-label19 {
                  width: 48px;
                  height: 48px;
                  margin-left: 6px;
                }
                .pwm-word49 {
                  width: 60px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: left;
                  margin-top: 6px;
                }
              }
              .pwm-outer27 {
                width: 60px;
                height: 78px;
                margin: 12px 0 0 28px;
                .pwm-label20 {
                  width: 48px;
                  height: 48px;
                  margin-left: 6px;
                }
                .pwm-word50 {
                  width: 60px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                  margin-top: 6px;
                }
              }
            }
            .pwm-section15 {
              width: 433px;
              height: 48px;
              margin: 14px 0 0 200px;
              .pwm-icon27 {
                width: 48px;
                height: 48px;
              }
              .pwm-label21 {
                width: 48px;
                height: 48px;
                margin-left: 249px;
              }
              .pwm-icon28 {
                width: 48px;
                height: 48px;
                margin-left: 40px;
              }
            }
            .pwm-section16 {
              width: 460px;
              height: 24px;
              margin: 6px 0 36px 194px;
              .pwm-txt25 {
                width: 60px;
                height: 24px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(40, 40, 40, 1);
                font-size: 14px;
                letter-spacing: 1.1666666269302368px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 24px;
                text-align: left;
              }
              .pwm-word51 {
                width: 60px;
                height: 24px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(40, 40, 40, 1);
                font-size: 14px;
                letter-spacing: 1.1666666269302368px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 24px;
                text-align: left;
                margin-left: 237px;
              }
              .pwm-word52 {
                width: 90px;
                height: 24px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(40, 40, 40, 1);
                font-size: 14px;
                letter-spacing: 1.1666666269302368px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 24px;
                text-align: center;
                margin-left: 13px;
              }
            }
          }
        }
      }
      .pwm-pic13 {
        z-index: 569;
        position: absolute;
        left: 560px;
        top: 373px;
        width: 69px;
        height: 36px;
      }
    }
    .pwm-word53 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 890px;
    }
    .pwm-bd18 {
      width: 1262px;
      height: 545px;
      margin: 60px 0 0 329px;
      .pwm-mod11 {
        position: relative;
        width: 516px;
        height: 545px;
        overflow: hidden;
        // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6e222aab89903bdaa634f07c1c506ffbabc16b5e37cd74463cb2f5d44cc667c0)
        //   100% no-repeat;
        .pwm-pic14 {
          position: absolute;
          left: 1px;
          top: 0;
          width: 515px;
          height: 545px;
        }
      }
      .pwm-mod12 {
        width: 645px;
        height: 545px;
        .pwm-txt26 {
          width: 265px;
          height: 29px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 21px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 29px;
          text-align: left;
        }
        .pwm-txt27 {
          width: 218px;
          height: 20px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 20px;
          text-align: left;
          margin-top: 6px;
        }
        .pwm-mod13 {
          width: 84px;
          height: 4px;
          background-color: rgba(37, 184, 47, 1);
          margin-top: 10px;
        }
        .pwm-word54 {
          width: 54px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 20px;
        }
        .pwm-paragraph4 {
          width: 645px;
          height: 60px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-word55 {
          width: 89px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 38px;
        }
        .pwm-paragraph5 {
          width: 645px;
          height: 90px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pwm-mod14 {
          width: 340px;
          height: 75px;
          margin: 32px 0 0 66px;
          .pwm-info25 {
            width: 63px;
            height: 20px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.75px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 20px;
            text-align: left;
            margin-top: 55px;
          }
          .pwm-mod15 {
            width: 17px;
            height: 31px;
            background: url(../../assets/images/procedure/hook.png) -1px -2px
              no-repeat;
            margin: 27px 0 0 19px;
          }
          .pwm-word56 {
            width: 106px;
            height: 55px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(37, 184, 47, 1);
            font-size: 40px;
            letter-spacing: 2px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 55px;
            text-align: left;
            margin-left: 109px;
          }
          .pwm-word57 {
            width: 47px;
            height: 20px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.75px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 20px;
            text-align: left;
            margin: 55px 0 0 -104px;
          }
          .pwm-mod16 {
            width: 35px;
            height: 34px;
            background: url(../../assets/images/procedure/hookdown.png) -2px -1px
              no-repeat;
            margin: 24px 0 0 48px;
          }
        }
        .pwm-mod17 {
          height: 60px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
          margin-top: 41px;
          width: 180px;
          cursor: pointer;
          .pwm-info26 {
            width: 85px;
            height: 29px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 0.41999998688697815px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 29px;
            text-align: left;
            margin: 15px 0 0 47px;
          }
        }
      }
    }
    .pwm-info27 {
      width: 181px;
      height: 50px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 36px;
      letter-spacing: 0.7200000286102295px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 50px;
      text-align: left;
      margin: 120px 0 0 869px;
    }
    .pwm-bd19 {
      width: 1236px;
      height: 264px;
      margin: 32px 0 0 342px;
      .pwm-pic15 {
        width: 340px;
        height: 264px;
      }
      .pwm-group10 {
        width: 47px;
        height: 25px;
        background: url(../../assets/images/procedure/yellowright.png)
          0px -5px no-repeat;
        margin: 120px 0 0 28px;
      }
      .pwm-pic16 {
        width: 340px;
        height: 264px;
        margin-left: 33px;
      }
      .pwm-group11 {
        width: 47px;
        height: 25px;
        background: url(../../assets/images/procedure/yellowright.png)
          0px -5px no-repeat;
        margin: 120px 0 0 34px;
      }
      .pwm-pic17 {
        width: 340px;
        height: 264px;
        margin-left: 27px;
      }
    }
    .pwm-bd20 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .pwm-word58 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pwm-txt28 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pwm-word59 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .pwm-bd21 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .pwm-paragraph6 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pwm-paragraph7 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pwm-infoBox5 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .pwm-bd22 {
      width: 1236px;
      height: 264px;
      margin: 40px 0 0 342px;
      .pwm-pic18 {
        width: 340px;
        height: 264px;
      }
      .pwm-mod18 {
        width: 47px;
        height: 25px;
        background: url(../../assets/images/procedure/yellowleft.png)
          0px -5px no-repeat;
        margin: 120px 0 0 29px;
      }
      .pwm-pic19 {
        width: 340px;
        height: 264px;
        margin-left: 32px;
      }
      .pwm-mod19 {
        width: 47px;
        height: 25px;
        background: url(../../assets/images/procedure/yellowleft.png)
          0px -5px no-repeat;
        margin: 120px 0 0 35px;
      }
      .pwm-pic20 {
        width: 340px;
        height: 264px;
        margin-left: 26px;
      }
    }
    .pwm-bd23 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .pwm-info28 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pwm-word60 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pwm-txt29 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .pwm-bd24 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .pwm-infoBox6 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pwm-paragraph8 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pwm-paragraph9 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .pwm-bd25 {
      height: 527px;
      background-color: rgba(38, 38, 38, 1);
      margin-top: 120px;
      width: 1920px;
      .pwm-box4 {
        width: 1520px;
        height: 362px;
        margin: 81px 0 0 201px;
        .pwm-mod20 {
          width: 1015px;
          height: 36px;
          margin-left: 128px;
          .pwm-info29 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pwm-txt30 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 156px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pwm-txt31 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pwm-txt32 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pwm-word61 {
            width: 87px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 1px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 36px;
            text-align: left;
            margin-left: 388px;
          }
        }
        .pwm-mod21 {
          width: 1138px;
          height: 56px;
          margin: 20px 0 0 128px;
          .pwm-block11 {
            width: 73px;
            height: 56px;
            .pwm-info30 {
              width: 73px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pwm-txt33 {
              width: 64px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pwm-block12 {
            width: 67px;
            height: 56px;
            margin-left: 121px;
            .pwm-txt34 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pwm-info31 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pwm-info32 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .pwm-word62 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .pwm-word63 {
            width: 210px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 16px 0 0 359px;
          }
        }
        .pwm-mod22 {
          width: 1262px;
          height: 88px;
          margin: 8px 0 0 128px;
          .pwm-wrap4 {
            width: 77px;
            height: 88px;
            .pwm-word64 {
              width: 68px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pwm-word65 {
              width: 77px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
            .pwm-info33 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pwm-wrap5 {
            width: 67px;
            height: 56px;
            margin-left: 117px;
            .pwm-txt35 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pwm-txt36 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pwm-wrap6 {
            width: 334px;
            height: 84px;
            margin-left: 667px;
            .pwm-word66 {
              width: 334px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .pwm-word67 {
              width: 220px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .pwm-txt37 {
          width: 68px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 0.761904776096344px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 8px 0 0 128px;
        }
        .pwm-txt38 {
          width: 632px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 0.8);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 81px 0 0 443px;
        }
        .pwm-mod23 {
          width: 1520px;
          height: 1px;
          // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng36c95fcb9cd157277513064f87626c479109168afad811c1b82e7eec57e512e8)
          //   0px 0px no-repeat;
          margin-top: 16px;
        }
      }
    }
  }
  .pwm-block13 {
    // z-index: 221;
    height: 396px;
   border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
    width: 360px;
    position: absolute;
    left: 588px;
    top: 2839px;
    .pwm-mod24 {
      width: 150px;
      height: 201px;
      margin: 92px 0 0 112px;
      .pwm-word68 {
        width: 97px;
        height: 33px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 24px;
        letter-spacing: 0.47999998927116394px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 33px;
        text-align: left;
        margin-left: 20px;
      }
      .pwm-section17 {
        width: 133px;
        height: 28px;
        margin-top: 36px;
        .pwm-wrap8 {
          width: 21px;
          height: 21px;
          background: url(../../assets/images/procedure/tick.png)
                0px 0px no-repeat;
          margin-top: 4px;
        }
        .pwm-word69 {
          width: 103px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
      }
      .pwm-section18 {
        width: 133px;
        height: 28px;
        margin-top: 24px;
        .pwm-layer8 {
          width: 21px;
          height: 21px;
          background: url(../../assets/images/procedure/tick.png)
                0px 0px no-repeat;
          margin-top: 4px;
        }
        .pwm-info34 {
          width: 103px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
      }
      .pwm-section19 {
        width: 150px;
        height: 28px;
        margin-top: 24px;
        .pwm-box6 {
          width: 21px;
          height: 22px;
          background: url(../../assets/images/procedure/tick.png)
                0px 0px no-repeat;
          margin-top: 4px;
        }
        .pwm-word70 {
          width: 120px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }
  .pwm-block13:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .pwm-word68 {
          color: rgba(255, 255, 255, 1);
        }
        .pwm-section17 .pwm-word69,
        .pwm-section18 .pwm-info34,
        .pwm-section19 .pwm-word70 {
          color: rgba(255, 255, 255, 1);
        }
        .pwm-section17 .pwm-wrap8,
        .pwm-section18 .pwm-layer8,
        .pwm-section19 .pwm-box6 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .pwm-bd10 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: 20px 0 0 50px;
          // margin: 90px 0 0 113px;
        }
      }
  .pwm-info35 {
    z-index: 615;
    position: absolute;
    left: 1010px;
    top: 6135px;
    width: 86px;
    height: 55px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(37, 184, 47, 1);
    font-size: 40px;
    letter-spacing: 2px;
    font-family: AlibabaPuHuiTiM;
    white-space: nowrap;
    line-height: 55px;
    text-align: left;
  }
}
</style>
<style src="../../assets/css/common.css" />